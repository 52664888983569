import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Disqus } from 'gatsby-plugin-disqus'

//import NavBar from "../components/navbar"
import Layout from "../components/layout"
import Seo from "../components/seo"

const FaqPage = () => {
	let disqusConfig = {
		url: "https://olymptrade.belobot.ru/faq",
		identifier: "FAQ",
		title: "FAQ",
	}
	
	return(
	<Layout>
		<Seo title="FAQ"/>
		<div className="container pt-4">
			<div className="row">
				<div className="col">
					<h3>FAQ</h3>
					<p>This page contains answers to the most frequently asked questions about Olymp Trade Robot. If you did not find the answer, please use the form at the bottom of the page. Or <Link to="/contacts">contact us</Link>.</p>
					
					<div className="accordion pb-3" id="accordionPanelsStayOpenExample">
					  <div className="accordion-item">
						<h2 className="accordion-header" id="panelsStayOpen-headingOne">
						  <button className="accordion-button fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
							How does the Olymp Trade Robot work?
						  </button>
						</h2>
						<div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
							<div className="accordion-body fs-6">
								Once launched, the robot tracks the 10 most profitable pairs. If a buy or sell signal comes in on any pair, then the robot makes a deal.
							</div>
						</div>
					  </div>
					  <div className="accordion-item">
						<h2 className="accordion-header" id="panelsStayOpen-headingTwo">
						  <button className="accordion-button fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
							How to set up Olymp Trade Robot?
						  </button>
						</h2>
						<div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
						  <div className="accordion-body fs-6">
							Olymp Trade Robot has 2 settings:<br/>
							1. The minimum percentage of profitability at which the transaction is concluded. If the profitability of the pair is below this value, the robot will not enter into a trade.<br/>
							2. The amount of the transaction. We recommend setting an amount no more than 10% of the deposit.
							</div>
						</div>
					  </div>
					  
						<div className="accordion-item">
							<h2 className="accordion-header" id="panelsStayOpen-headingThree">
								<button className="accordion-button fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
									How to switch Olymp Trade Robot to trade on a real or demo account?	
								</button>
							</h2>
							<div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree">
								<div className="accordion-body fs-6">
									If a robot is launched on a tab with a demo account, it trades on a demo account. If a real account is opened, the robot trades on a real account.
								</div>
							</div>
						</div>
						
						<div className="accordion-item">
							<h2 className="accordion-header" id="panelsStayOpen-heading4">
								<button className="accordion-button fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse4" aria-expanded="false" aria-controls="panelsStayOpen-collapse4">
									Olymp Trade Robot writes "Your account is not registered using the affiliate link". What to do?
								</button>
							</h2>
							<div id="panelsStayOpen-collapse4" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-heading4">
								<div className="accordion-body fs-6">
									To trade Olymp Trade Robot on a real account, the account must be registered using the <a href="https://olymptrade.belobot.ru/reg" target="_blank" rel="noreferrer">affiliate link</a>. If you are already registered on the Olymp Trade platform, you need to log out of your account and register again using the <a href="https://olymptrade.belobot.ru/reg" target="_blank" rel="noreferrer">affiliate link</a>.
								</div>
							</div>
						</div>						
						
						<div className="accordion-item">
							<h2 className="accordion-header" id="panelsStayOpen-heading5">
								<button className="accordion-button fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse5" aria-expanded="false" aria-controls="panelsStayOpen-collapse5">
									Will I be banned from the platform for using a robot?
								</button>
							</h2>
							<div id="panelsStayOpen-collapse5" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-heading5">
								<div className="accordion-body fs-6">
									It is impossible to officially use technical means for automatic trading on the Olymp Trade platform. But Olymp Trade Robot is made in such a way that it emulates the real actions of the user and the platform cannot track that the robot is being used. At the moment, none of the users of the robot has been blocked on the platform. If you have any problems using Olymp Trade Robot, please write to us.
								</div>
							</div>
						</div>
						
						<div className="accordion-item">
							<h2 className="accordion-header" id="panelsStayOpen-heading6">
								<button className="accordion-button fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse6" aria-expanded="false" aria-controls="panelsStayOpen-collapse6">
									I do not want to create a new account at Olymp Trade
								</button>
							</h2>
							<div id="panelsStayOpen-collapse6" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-heading6">
								<div className="accordion-body fs-6">
									You can try Olymp Trade Robot on a demo account. You do not need to create a new account for this. But in order to trade a robot on a real account, it is necessary that the trading account has been registered using an <a href="https://olymptrade.belobot.ru/reg" target="_blank" rel="noreferrer">affiliate link</a>. In the future, we will be able to connect existing accounts to the robot, but at the moment there is no such possibility.		
								</div>
							</div>
						</div>
						
						<div className="accordion-item">
							<h2 className="accordion-header" id="panelsStayOpen-heading7">
								<button className="accordion-button fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse7" aria-expanded="false" aria-controls="panelsStayOpen-collapse7">
									Does Olymp Trade Robot work through a proxy or VPN?
								</button>
							</h2>
							<div id="panelsStayOpen-collapse7" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-heading7">
								<div className="accordion-body fs-6">
									Olymp Trade Robot works through a proxy and VPN. But if you use a proxy or VPN, before starting trading on a real account, run Olymp Trade Robot on a demo account. If the message "Trading on the platform and depositing funds from the territory of your country is closed" appears, please refresh the page and try to start the robot again.
								</div>
							</div>
						</div>
						
						<div className="accordion-item">
							<h2 className="accordion-header" id="panelsStayOpen-heading8">
								<button className="accordion-button fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse8" aria-expanded="false" aria-controls="panelsStayOpen-collapse8">
									The robot makes deals only on an open pair, although signals are received on different pairs
								</button>
							</h2>
							<div id="panelsStayOpen-collapse8" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-heading8">
								<div className="accordion-body fs-6">
									Before trading on a real account, always check the correctness of the Olymp Trade Robot on a demo account. If the robot concludes deals on only one pair, then the robot module has not been fully loaded, you need to refresh the page and restart the robot.
								</div>
							</div>
						</div>
																																																																							
					</div>
					<p>
						Still have questions? You can ask them here:
					</p>
					<Disqus config={disqusConfig} />
				</div>				
			</div>
		</div>
		
		
	</Layout>
	)
}

export default FaqPage